import { theme, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'

import { FAQAccordionBody } from '../../FAQs/FAQAccordionBody'
import { FAQAccordionHeader } from '../../FAQs/FAQAccordionHeader'
import Accordion from '../../shared/AccordionV2'
import { ExcessOverlayStyles } from './ExcessOverlayStyles'
const { h2: H2, h5: H5, paragraphBody: ParagraphBody } = typographyRem

interface ExcessOverlayProps {
  voluntaryExcess: number
  compulsoryExcess: number
  isTrackerless: boolean
}

export const ExcessOverlay = ({ voluntaryExcess, compulsoryExcess, isTrackerless }: ExcessOverlayProps) => {
  const excessSteps = [
    {
      name: 'total-excess',
      title: `Total excess if ${isTrackerless ? 'policy' : 'Miles Tracker'} is activated.`,
      value: `£${(voluntaryExcess + compulsoryExcess).toFixed(2)}`,
      text: `Our compulsory excess is £${compulsoryExcess} but you can choose to add your own voluntary excess on top. The higher you set
      your total excess, the lower your annual premium will be. But the higher your total excess cost, the more
      you'll end up paying if anything does happen.`,
    },
    {
      name: 'inactive-excess-increase',
      title: ` If ${isTrackerless ? 'policy isn’t activated' : 'Miles Tracker is unplugged'} your total excess will
      increase to.`,
      value: `£${(voluntaryExcess + compulsoryExcess + 500).toFixed(2)}`,
      text: `If your
      ${isTrackerless ? ' policy isn’t activated by being connected to your vehicle ' : ' Miles Tracker is unplugged '}
      at the time of any incident, your excess will automatically be increased by £500.`,
    },
    {
      name: 'repairer-excess',
      title: 'Additional excess if you don’t use an approved repairer.',
      value: '£250',
      text: 'This is the additional cost you’ll need to pay towards repairs if you don’t use one of the insurer’s approved repairers.',
    },
    {
      name: 'misfuelling-excess',
      title: 'Misfuelling excess (excluding engine damage).',
      value: '£75',
      text: 'This is the cost you’ll need to pay towards repairs if you or any named driver accidentally fills your car’s fuel tank with the wrong fuel type. If the engine is damaged as a result, your standard excess will apply.',
    },
    {
      name: 'glass-replacement-excess',
      title: 'Glass replacement excess.',
      value: '£75',
      text: 'This is the cost you’ll need to pay towards repairs if you need to replace any glass in your car’s windows, if using one of the insurer’s approved repairers.',
    },
    {
      name: 'glass-repair-excess',
      title: 'Glass repair excess.',
      value: '£25',
      text: 'This is the cost you’ll need to pay towards repairs if you need to repair any glass in your car’s windows, if using one of the insurer’s approved repairers.',
    },
  ]
  return (
    <ExcessOverlayStyles>
      <div className="LoNotSensitive">
        <H2 className="overlay-heading">Excesses.</H2>
        <ParagraphBody className="overlay-content">
          The excess is the amount of money you’ll pay towards the replacement or repair of your car or medical costs
          after an incident.
        </ParagraphBody>
        <Accordion>
          {excessSteps.map((step, i) => (
            <Accordion.Item expanded={i === 0} index={step.name}>
              <FAQAccordionHeader background={theme.tints.lightAubergine} closeOnOpen={true}>
                <div className="header-container">
                  <H5 className="header-text">{step.title}</H5>
                  <H5>{step.value}</H5>
                </div>
              </FAQAccordionHeader>
              <FAQAccordionBody background={theme.tints.lightAubergine}>{step.text}</FAQAccordionBody>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </ExcessOverlayStyles>
  )
}
