import React from 'react'

import config from '../../../config'
import { useQuote } from '../../../hooks/useQuote'
import { AddExtraAction, RemoveExtraAction } from '../../../utils/extrasOverlayTypes'
import { ExtrasOverlay } from './ExtrasOverlay'
import { noCoverOption } from './utils'

export const LegalCoverOverlay = () => {
  const quote = useQuote({})
  const offeredFreeLegalCover = quote?.details?.site?.wasOfferedFreeLegal
  const legalCoverType = offeredFreeLegalCover ? 'waived_legal_cover_1' : 'legal_cover_1'
  const legalCoverOptions = quote?.extras.filter(extra => extra?.code === legalCoverType)
  const legalTiers = legalCoverOptions.map(tier => {
    return {
      ...tier,
      selected: legalCoverOptions.some(tier => tier.selected),
    }
  })
  const tiers = [...legalTiers, noCoverOption]

  return (
    <ExtrasOverlay
      heading="Do you need Legal Expense Cover?"
      openingPara="Get extra protection against legal fees from accidents and prosecutions."
      subHeading="Choose your level of cover:"
      linkHeading="Find out more:"
      links={[
        {
          label: 'ARC Policy Booklet',
          href: config.docs.legalCover.handbook,
        },
        {
          label: 'Insurance Product Information Document (IPID)',
          href: config.docs.legalCover.ipid,
        },
      ]}
      logo="arc-logo.svg"
      smallPrint={false}
      type="legal"
      tiers={tiers}
      tierSelected={legalCoverOptions.filter(tier => tier?.selected)}
      removeAction={RemoveExtraAction.RemoveLegalCover}
      addAction={AddExtraAction.AddLegalCover}
    />
  )
}
