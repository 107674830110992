import { ButtonPrimary, Link, typographyRem } from '@by-miles/bymiles-styles'
import { DateInputSplit, Input } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import { postcodeRegex, vehicleReg } from '@by-miles/regex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Cookie from 'js-cookie'
import React, { useEffect, useState } from 'react'

import config from '../../config'
import { useRetrieveQuoteByIdLazyQuery, useRetrieveQuoteByRegLazyQuery } from '../../generated/graphql'
import { DateErrorState } from '../QuoteFlow/utils/dateTypes'
import { RetrievalFormStyle } from './RetrievalFormStyle'
const { h3: H3, h5: H5, labelSmall: LabelSmall } = typographyRem
dayjs.extend(utc)

interface RetrievalFormProps {
  quoteId: string
  cold: boolean
  className?: string
}

export const RetrievalForm = ({ quoteId, cold, className }: RetrievalFormProps) => {
  const [state, setState] = useState({
    lastName: '',
    dob: '',
    postcode: '',
    reg: '',
  })
  const [dateOfBirth, setDateOfBirth] = useState({
    day: undefined,
    month: undefined,
    year: undefined,
  })
  const [dateError, setDateError] = useState(false)
  const [dobError, setDobError] = useState(false)
  const [error, setError] = useState(false)
  const [retrieveQuoteByReg, { loading: regRetrievalLoading, error: regRetrievalError, data: retireveQuoteByRegData }] =
    useRetrieveQuoteByRegLazyQuery()
  const [retrieveQuoteById, { loading: idRetrievalLoading, error: idRetrievalError, data: retireveQuoteByIdData }] =
    useRetrieveQuoteByIdLazyQuery()

  useEffect(() => {
    if (regRetrievalError || idRetrievalError) {
      setError(true)
    }
  }, [regRetrievalError, idRetrievalError])

  useEffect(() => {
    if (retireveQuoteByRegData || retireveQuoteByIdData) {
      window.location.href = `${config.checkoutOrigin}/quote`
    }
  }, [retireveQuoteByRegData, retireveQuoteByIdData])

  const handleFormSubmit = async () => {
    const { lastName, dob, postcode, reg } = state
    const auth = { lastName, dob, postcode }
    if (cold) {
      retrieveQuoteByReg({ variables: { reg, input: auth } })
    } else {
      retrieveQuoteById({ variables: { id: quoteId, input: auth } })
    }
  }

  const clearQuoteCookieAndRoute = () => {
    DataLayer.customEvent({
      eventName: 'start new quote',
      section: 'start new quote',
      eventType: 'button click',
    })

    Cookie.remove(config.quoteIdCookieName, { path: '/', domain: config.strippedDomain })
    window.location.href = `${config.checkoutOrigin}/details/car`
  }

  const handleCheckDate = () => {
    if (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year) {
      return
    } else if (dateError) {
      setState({ ...state, dob: '' })
      setDobError(true)
    } else {
      const { year, month, day } = dateOfBirth
      const dob = dayjs.utc(`${year}/${month}/${day}`).toISOString()
      setState({ ...state, dob })
    }
  }

  const handleDobErrors = (errors: DateErrorState) => {
    if (errors.day || errors.month || errors.year) {
      setDateError(true)
    }
  }

  return (
    <RetrievalFormStyle className={`${className} ${error ? 'error' : ''}`}>
      <H3 className="title">Let’s get back to your quote</H3>
      <H5 className="sub-title">
        We’ll get you back to your quote in a few seconds, we just need you to confirm a few details.
      </H5>
      {cold && (
        <div className="input-wrapper">
          <Input
            placeHolder="Registration plate"
            name="reg"
            value={state.reg || ''}
            onChange={value => setState({ ...state, reg: value.toUpperCase() })}
            keepLabel={true}
            dataCy="reg"
            valid={!!state.reg?.match(vehicleReg)}
            className="reg-input"
          />
        </div>
      )}
      <div className="input-wrapper">
        <Input
          placeHolder="Last name"
          name="last-name"
          value={state.lastName}
          onChange={value => setState({ ...state, lastName: value })}
          keepLabel={true}
          dataCy="last-name-input"
          valid={state?.lastName?.length > 0}
          className="reg-input"
        />
      </div>
      <LabelSmall className="form-label">Date of Birth</LabelSmall>
      <DateInputSplit
        dataCy="dob-text-field"
        showLabels={true}
        toggleErrorMessage={!!dobError}
        validateOnBlur={false}
        onBlur={() => handleCheckDate()}
        onError={handleDobErrors}
        onChange={(value, field) => {
          setDobError(false)
          setDateError(false)
          setDateOfBirth({
            ...dateOfBirth,
            [field]: value,
          })
        }}
        defaultValue={dateOfBirth}
      />
      <div className="input-wrapper ">
        <Input
          placeHolder="Postcode"
          name="postcode"
          value={state.postcode}
          onChange={value => setState({ ...state, postcode: value.toUpperCase() })}
          keepLabel={true}
          dataCy="postcode"
          valid={!!state?.postcode?.match(postcodeRegex)}
          className="reg-input"
        />
      </div>
      {error && (
        <div className="errors">
          <div className="">
            Sorry those details don't seem to match the quote we've saved. Please check and try again.
          </div>
        </div>
      )}
      <div className="form-divider" />
      <div className="button-wrapper">
        <ButtonPrimary
          label="Open My Quote"
          shadow
          disabled={!state.lastName || !state.dob || !state.postcode || regRetrievalLoading || idRetrievalLoading}
          onClick={() => handleFormSubmit()}
          style={{ width: '176px', height: '48px', padding: '16px 12px' }}
          dataCy="open-my-quote-btn"
        />
        <Link onClick={() => clearQuoteCookieAndRoute()} text="Start a new quote" dataCy="start-new-quote-btn" />
      </div>
    </RetrievalFormStyle>
  )
}
