import { ButtonSecondary, theme } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { ProviderContext } from '../../Provider'
import { GenericOverlay } from '../Overlays'
import { SaveQuote } from '../Overlays/SaveQuote'
import { Logo } from './Logo'

const {
  grid: { resolution, spacing },
} = theme

const HeaderStyle = styled.header<{ showChurchill: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background: white;

  #modal-background {
    z-index: 15;
  }
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    ${({ showChurchill }) => `padding: ${showChurchill ? spacing.xs : spacing.s}px ${resolution.mobile.margin}px`};
    box-sizing: border-box;

    @media (min-width: ${resolution.tablet.width}px) {
      ${({ showChurchill }) => `padding: ${showChurchill ? spacing.s : spacing.base}px ${spacing.md}px`};
    }

    @media (min-width: ${resolution.desktop.width}px) {
      width: ${resolution.desktop.width}px;
      margin: auto;
    }
  }
`
interface headerTypes {
  onClick: () => void
  onSaveQuoteClick: () => void
  hideSaveQuote: boolean
}

export const Header = ({ onClick, onSaveQuoteClick, hideSaveQuote }: headerTypes) => {
  const { state, handleCloseModal } = useContext(ProviderContext)

  return (
    <HeaderStyle showChurchill={state.showChurchill}>
      <div className="container">
        <Logo onClick={onClick} svgStyle={{ cursor: 'pointer' }} showChurchill={state.showChurchill} />
        {!hideSaveQuote && (
          <ButtonSecondary label="Save&nbsp;Quote" onClick={onSaveQuoteClick} tabFocusTrap={state.showModal ? -1 : 0} />
        )}
      </div>
      {state.showModal && state.activeModal === 'saveQuoteModal' && (
        <GenericOverlay onClose={() => handleCloseModal('saveQuoteModal')} overlay={<SaveQuote />} />
      )}
    </HeaderStyle>
  )
}
