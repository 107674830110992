import { theme } from '@by-miles/bymiles-styles'
import { TrustPilotFallback } from '@by-miles/bymiles-styles'
import React, { useEffect } from 'react'
import styled from 'styled-components'

// * Refactor into one widget and remove unused styles

const TrustPilotStyle = styled.div`
  .trustpilot-widget {
    border-radius: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    display: inline-block;
    z-index: 9;
    top: 0;
    left: 0;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: 140px;
    }
  }
`

interface TrustPilotWidgetProps {
  className?: string
}

const TrustPilotWidget = ({ className }: TrustPilotWidgetProps) => {
  useEffect(() => {
    if (!window.Cypress && window.Trustpilot && window.Trustpilot.loadFromElement) {
      const trustbox = document.getElementById('trustBox')
      window.Trustpilot.loadFromElement(trustbox)
    }
  })
  return (
    <TrustPilotStyle className={className || ''}>
      <div
        id="trustBox"
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="596dce770000ff0005a6e743"
        data-style-height="100px"
        data-style-width="170px"
        data-theme="dark"
      >
        <TrustPilotFallback logoTheme="light" />
      </div>
    </TrustPilotStyle>
  )
}

export default TrustPilotWidget
