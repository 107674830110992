import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

const {
  grid: { spacing },
} = theme

export const ExtrasOverlayStyles = styled.div`
  .overlay-heading {
    margin-bottom: ${spacing.base}px;
  }

  .opening-para {
    margin-bottom: ${spacing.s}px;
  }

  .sub-heading {
    margin-bottom: ${spacing.base}px;
  }

  .cta {
    margin-top: ${spacing.xs}px;
    margin-bottom: ${spacing.base}px;
    width: 100%;
    margin-top: ${spacing.s}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: auto;
    }
  }
  .small-print-heading {
    margin-top: ${spacing.s}px;
    margin-bottom: ${spacing.xs}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: ${spacing.s}px;
    }
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -${spacing.xs}px;
      background: ${theme.tints.darkAubergine};
      left: 0;
      top: -${spacing.s}px;
    }
  }
  .footnote {
    color: ${theme.primaryColours.aubergine.main};
    &:not(:last-child) {
      margin-bottom: ${spacing.s}px;
    }
  }
  .small-print {
    margin-bottom: -6px;
    color: ${theme.primaryColours.aubergine.main};
  }
  .links {
    margin-bottom: ${spacing.xs}px;

    &:last-of-type {
      margin-bottom: ${spacing.s}px;
    }
  }

  .badge {
    background: ${theme.tints.mint};
    padding: 4px 12px 4px 5px;
    display: inline-block;
    margin-bottom: ${spacing.s}px;

    .flex {
      display: flex;

      span {
        margin-right: ${spacing.xxs}px;
      }
    }
  }

  .radio-list-error-message {
    position: relative;
  }
  @media (min-width: ${theme.grid.resolution.tablet.width}px) {
    .opening-para {
      margin-bottom: ${spacing.base}px;
    }
  }
  @media (min-width: ${theme.grid.resolution.desktop.width}px) {
    .overlay-heading {
      margin-bottom: ${spacing.md}px;
    }
  }
`
