import { typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'
const { paragraphBody: ParagraphBody } = typographyRem

export const SDPC = () => (
  <>
    <ParagraphBody className="overlay-text">
      This covers the policyholder and all named drivers for things like visiting family and friends, school runs and
      trips to the shops.
    </ParagraphBody>
    <ParagraphBody className="overlay-text">
      In addition, both the policyholder and all named drivers can use the car to travel to and from their usual place
      of work. But it <span className="bold">doesn’t</span> cover any driving needed to be done as part of the
      policyholder or named driver’s job - like visiting clients, travelling to meetings, or driving to different sites,
      or locations in connection with work.
    </ParagraphBody>
  </>
)
