import { ButtonPrimary, IconTick, MultiVariantLabel, theme, typographyRem } from '@by-miles/bymiles-styles'
import React, { useContext, useEffect, useState } from 'react'

import { PaymentType } from '../../../generated/graphql'
import { useQuote } from '../../../hooks/useQuote'
import { ProviderContext } from '../../../Provider'
import { numbersWithCommas } from '../../../utils/numbersWithComas'
import { SkeletonLoader } from '../SkeletonLoader'
import { StickyPricingStyles } from './StickyPricingStyles'
const { h3: H3, h4: H4, labelMedium: LabelMedium } = typographyRem

interface StickyPricingProps {
  loading: boolean
  className: string
  payTodayAmount: number
  projectedAnnual: number
  payMonthlyAmount: number
  onClick: () => void
  buttonError: boolean
  estimatedMileage: number
  showModal: boolean
}

export const StickyPricing = (props: StickyPricingProps) => {
  const { state } = useContext(ProviderContext)
  const [counted, setCounted] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)
  const quote = useQuote()
  useEffect(() => {
    if (props.loading) {
      setShowAnimation(true)
      setCounted(false)
      setTimeout(function () {
        setCounted(true)
        if (!props.loading) {
          setShowAnimation(false)
        }
      }, 1000)
    } else {
      if (counted) {
        setShowAnimation(false)
      }
    }
  }, [props.loading, counted])
  const isRefundAtRenewal = quote?.details?.policy?.paymentType === PaymentType.RefundAtRenewalYearly
  const { quotePricing } = state
  return (
    <StickyPricingStyles className={`${props.className}`}>
      <div className="container LoNotSensitive">
        <div className="content">
          <div className={`pay-today-container${isRefundAtRenewal ? '--refund-at-renewal' : ''}`}>
            {!isRefundAtRenewal && (
              <MultiVariantLabel
                text="Pay today"
                resolutions={{
                  mobile: 'LabelSmall',
                  tablet: 'LabelMedium',
                  desktop: 'LabelMedium',
                }}
              />
            )}
            {showAnimation ? (
              <SkeletonLoader
                width={{ mobile: '121px', tablet: '100px', desktop: '100px' }}
                height={{ mobile: '34px', tablet: '28px', desktop: '30px' }}
              />
            ) : (
              <H3 className="pay-today-price">{`£${
                isRefundAtRenewal ? quotePricing.fullPriceProjectedAnnual : props.payTodayAmount
              }`}</H3>
            )}
          </div>

          {isRefundAtRenewal ? (
            <div className="ticked-headers">
              <div className="ticked-headers__item">
                <IconTick fill={theme.primaryColours.mint.superDarkMint} />
                <LabelMedium>COMPREHENSIVE COVER</LabelMedium>
              </div>
              <div className="ticked-headers__item">
                <IconTick fill={theme.primaryColours.mint.superDarkMint} />
                <LabelMedium>{numbersWithCommas(props.estimatedMileage)} MILES PREPAID</LabelMedium>
              </div>
            </div>
          ) : (
            <div className="per-month-container">
              <MultiVariantLabel
                text="Average monthly"
                resolutions={{
                  mobile: 'LabelSmall',
                  tablet: 'LabelMedium',
                  desktop: 'LabelMedium',
                }}
                className="average-monthly"
              />
              {showAnimation ? (
                <SkeletonLoader
                  width={{ mobile: '47px', tablet: '182px', desktop: '187px' }}
                  height={{ mobile: '18px', tablet: '28px', desktop: '30px' }}
                />
              ) : (
                <>
                  <H4>{`£${props.payMonthlyAmount} a month`}</H4>
                </>
              )}
            </div>
          )}
        </div>
        <ButtonPrimary
          iconRight="IconArrowRight"
          label="continue"
          shadow
          onClick={props.onClick}
          loading={showAnimation}
          error={props.buttonError}
          tabFocusTrap={props.showModal ? -1 : 0}
        ></ButtonPrimary>
      </div>
    </StickyPricingStyles>
  )
}
