import { typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'

import config from '../../../config'
import { useQuote } from '../../../hooks/useQuote'
import { AddExtraAction, RemoveExtraAction } from '../../../utils/extrasOverlayTypes'
import { ExtrasOverlay } from './ExtrasOverlay'
import { noCoverOption } from './utils'
const { caption: Caption } = typographyRem

export const BreakdownCoverOverlay = () => {
  const quote = useQuote()
  const validBreakdownArray = ['breakdown_rr', 'breakdown_rrahot', 'breakdown_rrahote']
  const breakdownCoverOptions = quote?.extras.filter(extra => validBreakdownArray.includes(extra?.code))

  const breakdownTiers = breakdownCoverOptions.map(tier => {
    if (tier.code === 'breakdown_rrahot') {
      return {
        ...tier,
        popular: true,
      }
    } else return tier
  })
  const tiers = [...breakdownTiers, noCoverOption]

  return (
    <ExtrasOverlay
      heading="Do you need breakdown cover?"
      openingPara="Add cover from the UK’s most experienced breakdown provider for roadside assistance and more."
      subHeading="Choose your level of cover:"
      linkHeading="Find out more:"
      links={[
        {
          label: 'RAC Policy Booklet',
          href: `${config.legacyQuoteOrigin}/rac/handbook`,
        },
        {
          label: 'Insurance Product Information Document (IPID)',
          href: `${config.legacyQuoteOrigin}/rac/ipid`,
        },
      ]}
      smallPrint={
        <>
          <Caption className="small-print">*Provided you’re a 1/4 mile from home</Caption>
          <Caption className="small-print">**If at home max 10 mile tow only</Caption>
        </>
      }
      logo="rac-logo.svg"
      type={'breakdown'}
      tiers={tiers}
      tierSelected={tiers.filter(tier => tier?.selected)}
      removeAction={RemoveExtraAction.RemoveBreakdownCover}
      addAction={AddExtraAction.AddBreakdownCover}
    />
  )
}
