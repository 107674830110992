import { TrustPilotFallback } from '@by-miles/bymiles-styles'
import React, { useContext, useEffect } from 'react'

import { ProviderContext } from '../../../../Provider'
import { TrustpilotStyles } from './TustpilotStyles'

interface TrustpilotProps {
  trustpilotAvailable: boolean
}

export const Trustpilot = ({ trustpilotAvailable }: TrustpilotProps) => {
  const {
    state: { showModal },
  } = useContext(ProviderContext)
  useEffect(() => {
    if (!window.Cypress && window.Trustpilot) {
      const trustbox = document.getElementById('trustpilot')
      window.Trustpilot.loadFromElement(trustbox)
    }
  }, [])
  return trustpilotAvailable ? (
    <TrustpilotStyles showModal={showModal}>
      {showModal && <div className="trustpilot-placeholder" />}
      <div className="trustpilot">
        <div
          id="trustpilot"
          data-locale="en-GB"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="596dce770000ff0005a6e743"
          data-style-height="84px"
          data-style-width="100%"
          data-theme="light"
          data-stars="1,2,3,4,5"
          tab-index="-1"
          tabIndex={-1}
        >
          <TrustPilotFallback logoTheme="light" />
        </div>
      </div>
    </TrustpilotStyles>
  ) : (
    <TrustPilotFallback logoTheme="light" />
  )
}
