import { IconTick, MessageBanner, theme, typographyRem } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { ProviderContext } from '../../../Provider'
import { PerMile } from '../../PerMile'
import { DynamicOverlayTable } from '../DynamicOverlayTable'
import { calculateRefundFlexiblePricing } from './calculateRefundFlexiblePricing'
const { h2: H2, h5: H5, paragraphBody: ParagraphBody } = typographyRem

const FlexiblePricingOverlayStyle = styled.div`
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};
  .overlay-heading {
    margin-bottom: 24px;
  }

  .per-mile-container {
    background: ${theme.tints.lightAubergine};
    margin-top: ${theme.grid.spacing.md}px;
    .flexible-per-mile {
      width: 100%;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: 100%;
        padding: ${theme.grid.spacing.s}px;
      }
      @media (min-width: ${theme.grid.resolution.desktop.width}px) {
        width: 100%;
      }
    }
  }
  .banner-wrapper {
    margin-top: ${theme.grid.spacing.md}px;
    margin-bottom: ${theme.grid.spacing.md}px;
    &.message {
      div:first-child {
        padding-top: 5px;
      }
    }
    .icon {
      svg {
        position: relative;
        top: 3px !important;
      }
    }
    ul {
      margin-top: 15px;
      list-style-type: none;
      padding: 0;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        margin-top: 7px;
      }
      li {
        display: flex;
        margin-bottom: 8px;
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          margin-bottom: 0;
        }
        &:first-child {
          span.icon {
            width: 38px;
            @media (min-width: ${theme.grid.resolution.tablet.width}px) {
              width: 0;
            }
          }
        }
        p {
          font-size: 14px;
          margin-left: 19px;
          @media (min-width: ${theme.grid.resolution.tablet.width}px) {
            margin-left: 8px;
          }
        }
        span.icon {
          position: relative;
          width: 16px;
          display: block !important;
          @media (min-width: ${theme.grid.resolution.tablet.width}px) {
            width: 0;
          }

          svg {
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }
  .p-with-break {
    p {
      margin-bottom: 15px;
    }
  }
  .overlay-heading {
    margin-bottom: 18px;
  }

  .flexible-per-mile {
    &.refund-at-renewal {
      p.text {
        max-width: 95%;
      }
    }
  }
`
export const RefundFlexiblePricingOverlay = () => {
  const { state } = useContext(ProviderContext)
  const estimatedMiles = state.quotePricing.estimatedMiles
  const perMileCost = parseFloat(state.quotePricing.perMile)

  const estimates = calculateRefundFlexiblePricing({ estimatedMiles, perMileCost })

  return (
    <FlexiblePricingOverlayStyle>
      <div className="LoNotSensitive">
        <H2 className="overlay-heading">How does our flexible pricing work?</H2>
        <ParagraphBody className="overlay-content p-with-break">
          <p>
            Today, you’ll pay for the miles you think you’ll need this year. You can use the app or web dashboard to
            track your mileage, so you’ll always know how much is left in the tank. If you’re running low, you can
            simply top up at any time. Or, if you don’t fancy that, just keep driving and we’ll charge you for your
            extra miles monthly instead. They’ll cost the same either way, so it’s just a case of doing what works for
            you.
          </p>
          <p>
            And the best part? If you overestimate, we refund your unused miles at the end of the year. That means
            you’ll never pay for more than you need.
          </p>
        </ParagraphBody>
        <div className="per-mile-container">
          <PerMile
            largeLabel={true}
            description={PerMile.descriptions.refundAtRenewal}
            pence={perMileCost}
            background="transparent"
            className="flexible-per-mile refund-at-renewal"
          />
        </div>
        <div className="overlay-table-wrapper">
          <DynamicOverlayTable
            mileageTitle="Total mileage driven"
            costTitle="Refund amount"
            threeColumns={true}
            middleColumn="Top up amount"
            prettyEstimates={estimates}
          />
        </div>
        <div className="banner-wrapper message">
          <MessageBanner
            text={
              <>
                <H5 className="message-title">Don’t worry about your yearly spend.</H5>
                <ul>
                  <li>
                    <IconTick />
                    <p>
                      65% of our members use less than their estimated miles (as of Dec 2021), so would be on track for
                      a refund at the end of the year.
                    </p>
                  </li>
                  <li>
                    <IconTick />
                    <p>We never charge for more than 150 miles a day.</p>
                  </li>
                </ul>
              </>
            }
            icon="IconThumbsUp"
          />
        </div>
        <H5 className="overlay-heading">What happens if I drive more than my estimated miles?</H5>
        <ParagraphBody className="overlay-content p-with-break">
          No matter how far you drive, you’ll still be insured. If you go over your estimated miles, there aren’t any
          fees or penalties either. You simply pay for the extra miles.
          <br />
          <br />
          There are two ways to do this. You can either top up or, if you don’t fancy that, just keep driving and we’ll
          charge for the exact miles you’ve driven each month. They’ll cost the same either way, so it’s just a case of
          doing what works for you.
          <br />
          <br />
          The amount we charge you per mile will be always the same during your policy year (unless you change your
          policy information during that time). In other words, your extra miles will cost exactly the same as if you
          included them in your estimate to begin with.
          <br />
          <br />
          We’ll let you know when you’re down to 80% of your pre-paid miles, and you can always keep track of what’s
          left in the tank using our app or web dashboard (sort of like a smart meter for your car insurance). If you do
          top up, any miles you don’t end up using get refunded at the end of the year - so you’ll never pay for more
          than you need.
        </ParagraphBody>
      </div>
    </FlexiblePricingOverlayStyle>
  )
}
