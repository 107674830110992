import React from 'react'

import { ExtrasPanel } from './ExtrasPanel'
import { ExtrasStyles } from './ExtrasStyles'

interface ExtrasProps {
  // * Fix type def
  breakdownCoverTiers: any[]
  legalCoverTiers: any[]
  carHireCoverTiers: any[]
  hasLegal: boolean
  hasBreakdown: boolean
  hasCarHire: boolean
  handleOnClick: (type: string) => void
  tabFocusTrap?: number
}

export const Extras = ({
  breakdownCoverTiers,
  legalCoverTiers,
  carHireCoverTiers,
  hasBreakdown,
  hasLegal,
  hasCarHire,
  handleOnClick,
  tabFocusTrap,
}: ExtrasProps) => {
  return (
    <ExtrasStyles>
      <ExtrasPanel
        logo="rac-logo.svg"
        image={'breakdown-illustration.svg'}
        hasSelectedTier={hasBreakdown}
        tiers={breakdownCoverTiers}
        title="breakdown cover"
        handleOnClick={() => handleOnClick('breakdown-cover')}
        type="rac"
        customDescription="Add cover from the UK’s most experienced breakdown provider for roadside assistance and more."
        tabFocusTrap={tabFocusTrap}
      />
      <ExtrasPanel
        logo="arc-logo.svg"
        image={'legal-illustration.svg'}
        hasSelectedTier={hasLegal}
        tiers={legalCoverTiers}
        title="legal expense cover"
        handleOnClick={() => handleOnClick('legal-cover')}
        type="legal"
        customDescription="Get extra protection against legal fees from accidents and prosecutions."
        tabFocusTrap={tabFocusTrap}
      />
      {carHireCoverTiers?.length > 0 && (
        <ExtrasPanel
          image={'car-hire-illustration.svg'}
          hasSelectedTier={hasCarHire}
          tiers={carHireCoverTiers}
          title="Replacement Vehicle Hire"
          handleOnClick={() => handleOnClick('car-cover')}
          type="car-hire"
          customDescription="A higher level of courtesy car cover than your standard comprehensive car insurance policy"
          tabFocusTrap={tabFocusTrap}
        />
      )}
    </ExtrasStyles>
  )
}
