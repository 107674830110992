import { MultiVariantLabel, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'

import { DynamicOverlayTableStyle } from './DynamicOverlayTableStyles'
const { h4: H4 } = typographyRem

export const DynamicOverlayTable = ({
  prettyEstimates,
  mileageTitle,
  costTitle,
  threeColumns = false,
  middleColumn = '',
  ...props
}) => {
  return (
    <DynamicOverlayTableStyle className={props.className} id={props.id}>
      <div className={`table-wrapper ${threeColumns ? 'three-columns' : ''}`}>
        <div className="title-wrapper">
          <div className={`${threeColumns ? 'title-item firstTitle' : ''}`}>
            <MultiVariantLabel
              text={mileageTitle}
              resolutions={{
                mobile: 'LabelMedium',
                tablet: 'LabelLarge',
                desktop: 'LabelLarge',
              }}
              className="column-title"
            />
          </div>

          {threeColumns && middleColumn && (
            <div className={`${threeColumns ? 'title-item middleTitle' : ''}`}>
              <MultiVariantLabel
                text={middleColumn}
                resolutions={{
                  mobile: 'LabelMedium',
                  tablet: 'LabelLarge',
                  desktop: 'LabelLarge',
                }}
                className="column-title"
              />
            </div>
          )}
          <div className={`${threeColumns ? 'title-item finalTitle' : ''}`}>
            <MultiVariantLabel
              text={costTitle}
              resolutions={{
                mobile: 'LabelMedium',
                tablet: 'LabelLarge',
                desktop: 'LabelLarge',
              }}
              className="column-title cost"
            />
          </div>
        </div>

        {prettyEstimates.map(item =>
          item.active ? (
            <div className="item-row active">
              <div className={`item-wrapper ${threeColumns ? 'first' : ''}`}>
                <H4 className="item-active first">{item.estimateMiles}</H4>
              </div>
              {threeColumns && middleColumn && (
                <>
                  <div className={`item-wrapper middle-item`}>
                    <H4 className="item-active middle-item">
                      {item.middleColumnValue ? item.middleColumnValue : '£0.00'}
                    </H4>
                  </div>
                </>
              )}
              <div className={`item-wrapper cost`}>
                <H4 className="item-active">{`£${item.estimateCost}`}</H4>
              </div>
            </div>
          ) : (
            <div className="item-row">
              <div className={`item-wrapper ${threeColumns ? 'first' : ''} `}>
                <MultiVariantLabel
                  text={item.estimateMiles}
                  className="item first"
                  resolutions={{
                    mobile: 'LabelMedium',
                    tablet: 'LabelLarge',
                    desktop: 'LabelLarge',
                  }}
                />
              </div>

              {threeColumns && middleColumn && (
                <div className="item-wrapper middle-item">
                  <MultiVariantLabel
                    text={item.middleColumnValue ? item.middleColumnValue : '£0.00'}
                    className={`item middle-item ${item.middleColumnValue ? ' red ' : ''}`}
                    resolutions={{
                      mobile: 'LabelMedium',
                      tablet: 'LabelLarge',
                      desktop: 'LabelLarge',
                    }}
                  />
                </div>
              )}
              <div className="item-wrapper cost">
                <MultiVariantLabel
                  text={`£${item.estimateCost}`}
                  className={`item  ${item.estimateCost > 0 ? 'green' : ''}`}
                  resolutions={{
                    mobile: 'LabelMedium',
                    tablet: 'LabelLarge',
                    desktop: 'LabelLarge',
                  }}
                />
              </div>
            </div>
          ),
        )}
      </div>
    </DynamicOverlayTableStyle>
  )
}
