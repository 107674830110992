import { IconOpenLink, theme, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'
const { paragraphBody: ParagraphBody } = typographyRem

const ExternalLinksStyles = styled.div`
  .link-wrapper {
    display: flex;
    a {
      margin-left: 8px;
      p {
        color: ${theme.primaryColours.aubergine.main};
      }
    }
  }
`

export const ExternalLinks = ({ href, label }) => (
  <ExternalLinksStyles>
    <div className="link-wrapper">
      <IconOpenLink />
      <a href={href} target="_blank" rel="noreferrer">
        <ParagraphBody>{label}</ParagraphBody>
      </a>
    </div>
  </ExternalLinksStyles>
)
