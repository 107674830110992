import { CheckButtonTheme, IconCheckButton, typographyPx } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { ProviderContext } from '../../../Provider'
import { BreadcrumbStyled, StepIndicatorStyles } from './StepIndicatorStyles'
const { labelSmall: LabelSmall } = typographyPx

type Step = {
  name: string
}

type Breadcrumb = {
  status: string | boolean
  name: string
}

interface StepIndicatorProps {
  stepsArray?: Step[]
  currentPage: string
  onClick?: (value: string) => void
  obdFit?: string
  onNextClicked?: (value: string) => void
  buttonTheme?: CheckButtonTheme
  className?: string
}

export const StepIndicator = ({
  stepsArray,
  currentPage,
  onClick,
  obdFit,
  onNextClicked,
  buttonTheme = CheckButtonTheme.Dark,
  className,
}: StepIndicatorProps) => {
  const { validatePageAndRedirect } = useContext(ProviderContext)
  const history = useHistory()

  const defaultArr = stepsArray || [
    { name: 'quote' },
    { name: 'cover' },
    { name: 'how?' },
    { name: 'confirm' },
    { name: 'pay' },
  ]
  const pageIndex = defaultArr.findIndex(el => el.name === currentPage)
  const nextStep = defaultArr[pageIndex + 1]

  const getSteps = () => {
    return defaultArr.map((s, i) =>
      i <= pageIndex ? { ...s, status: i === pageIndex ? 'inprogress' : 'complete' } : { ...s, status: false },
    )
  }

  const handleStepClicked = (target: string) => {
    if (
      (currentPage === 'how?' && target === 'cover') ||
      (window.location.pathname === '/cover' && target === 'quote')
    ) {
      history.push(target)
    }
    if (onNextClicked && target === 'pay') {
      return onNextClicked(target)
    }
    validatePageAndRedirect(target, obdFit)
  }
  const handleClick = (breadcrumb: Breadcrumb) => {
    if ((breadcrumb?.name === nextStep?.name || breadcrumb?.status === 'complete') && nextStep?.name) {
      if (onClick) {
        onClick(breadcrumb.name)
      } else {
        handleStepClicked(breadcrumb.name)
      }
    }
    return false
  }

  const steps = getSteps()

  return (
    <StepIndicatorStyles buttonTheme={buttonTheme} className={className}>
      <div className="step-wrapper">
        {steps.map((breadcrumb, i) => {
          return (
            <BreadcrumbStyled clickable={breadcrumb.name === nextStep?.name || breadcrumb.status === 'complete'}>
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-container">
                  <div className="breadcrumb" onClick={() => handleClick(breadcrumb)}>
                    <IconCheckButton
                      status={breadcrumb.status}
                      iconFill={null}
                      backgroundFill={null}
                      buttonTheme={buttonTheme}
                    />
                  </div>
                  {i < steps.length - 1 && <div className="divider" />}
                </div>
              </div>
            </BreadcrumbStyled>
          )
        })}
      </div>
      <div className="name-wrapper">
        {steps.map((breadcrumb, i) => {
          return (
            <BreadcrumbStyled clickable={breadcrumb.name === nextStep?.name || breadcrumb.status === 'complete'}>
              <LabelSmall onClick={() => handleClick(breadcrumb)} className="stage-title">
                {breadcrumb.name}
              </LabelSmall>
            </BreadcrumbStyled>
          )
        })}
      </div>
    </StepIndicatorStyles>
  )
}
