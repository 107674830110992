import React from 'react'

import config from '../../../config'
import { useQuote } from '../../../hooks/useQuote'
import { AddExtraAction, RemoveExtraAction } from '../../../utils/extrasOverlayTypes'
import { ExtrasOverlay } from './ExtrasOverlay'
import { noCoverOption } from './utils'

export const CarCoverOverlay = () => {
  const quote = useQuote()
  const carHireCoverOptions = quote?.extras.filter(extra => extra?.code === 'replacement_vehicle')
  const carCoverTiers = carHireCoverOptions.map(tier => {
    return {
      ...tier,
      selected: carHireCoverOptions.some(tier => tier.selected),
      description: 'Replacement Vehicle Hire',
    }
  })
  const tiers = [...carCoverTiers, noCoverOption]
  return (
    <ExtrasOverlay
      heading="Do you need enhanced courtesy car cover?"
      openingPara="A higher level of courtesy car cover than your standard comprehensive car insurance policy."
      linkHeading="Find out more:"
      footNotes={[
        'If your car has been specially modified to accommodate a driver or passenger with disabilities and another suitable vehicle cannot be found, you’ll be reimbursed up to £30.00 per day for transportation costs during the period when your car is not available, for up to 21 days.',
        '* Subject to terms and conditions. For more details on the Replacement Vehicle Hire cover, including exclusions, it’s important that you read the documentation below.',
      ]}
      links={[
        {
          label: 'Replacement Vehicle Hire Policy Handbook',
          href: config.docs.carHire.handbook,
        },
        {
          label: 'Replacement Vehicle Hire Insurance Product Information Document (IPID)',
          href: config.docs.carHire.ipid,
        },
      ]}
      type="car-hire"
      smallPrint={false}
      tiers={tiers}
      tierSelected={carHireCoverOptions.filter(tier => tier?.selected)}
      removeAction={RemoveExtraAction.RemoveCarHireCover}
      addAction={AddExtraAction.AddCarHireCover}
    />
  )
}
