import { ButtonGeneral, IconWarning, theme, typographyRem } from '@by-miles/bymiles-styles'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
const { paragraphBody: ParagraphBody } = typographyRem

const ErrorBannerStyle = styled.div`
  width: 100%;
  height: 72px;
  background: ${theme.tints.amber};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 19;
  line-height: 72px;
  box-shadow: ${theme.shadows.doubleUpShadow};
  p {
    font-weight: 700;
    color: ${theme.primaryColours.aubergine.main};
    left: -4px;
    position: relative;
  }
  button.show-me {
    height: 40px;
    left: 10px;
    @media (max-width: ${theme.grid.resolution.tablet.width}px) {
      width: 121px;
      padding: 16px 22px;
      flex: 1 0 121px;
      margin-left: ${theme.grid.spacing.s}px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: ${theme.grid.spacing.s}px;
    margin: auto;
    .icon {
      display: none;
    }
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: 450px;
      padding: 0;
      .icon {
        display: block;
        svg {
          position: relative;
          top: 8px;
          left: -6px;
        }
      }
    }
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      width: 470px;
    }
  }
`

interface ErrorBannerProp {
  numberOfErrors: number
  handleButtonClick: () => void
}

export const ErrorBanner = ({ numberOfErrors, handleButtonClick }: ErrorBannerProp) => {
  const [count, setCount] = useState(0)
  // * Stops screen reader reading out message after every error update
  useEffect(() => {
    setCount(count + 1)
  }, [numberOfErrors])
  return (
    <ErrorBannerStyle>
      <div className="wrapper">
        <div className="icon">
          <IconWarning />
        </div>
        <ParagraphBody aria-live={count <= 1 ? 'assertive' : 'off'}>
          Oops, there {numberOfErrors > 1 ? `are ${numberOfErrors} mistakes` : `is ${numberOfErrors} mistake`} with your
          form.
        </ParagraphBody>
        <ButtonGeneral onClick={handleButtonClick} className="show-me" label="show me" />
      </div>
    </ErrorBannerStyle>
  )
}
