import { IconTick, Tag, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'

import { ExtrasRadioCardContentStyles } from './ExtrasRadioCardContentStyles'
const { paragraphBody: ParagraphBody } = typographyRem

interface ExtrasRadioCardContentProps {
  offeredFreeLegalCover: boolean
  isLegalCover: boolean
  amountInPounds: number
  aboveBullets: string
  bullets: string[] | undefined
  code: string
}

export const ExtrasRadioCardContent = ({
  offeredFreeLegalCover,
  isLegalCover,
  amountInPounds,
  aboveBullets,
  bullets,
  code,
}: ExtrasRadioCardContentProps) => {
  return (
    <ExtrasRadioCardContentStyles>
      {code !== 'none' && (
        <>
          {offeredFreeLegalCover && isLegalCover ? (
            <div className="free-legal-container">
              <ParagraphBody>
                for <span style={{ textDecoration: 'line-through' }}>{`£29.99/year`}</span>
              </ParagraphBody>
              <ParagraphBody>£0.00</ParagraphBody>
              <Tag class="tag" text="free this year!" />
            </div>
          ) : (
            <ParagraphBody className="price">{`for £${amountInPounds}/year`}</ParagraphBody>
          )}
        </>
      )}
      {(aboveBullets || bullets) && <div className="divider" />}
      {aboveBullets && <ParagraphBody className="above-bullets">{aboveBullets}</ParagraphBody>}
      {bullets && (
        <div className="breakdown-benefits">
          <ul>
            {bullets.map(bullet => (
              <li>
                <IconTick />
                <ParagraphBody className="item">{bullet}</ParagraphBody>
              </li>
            ))}
          </ul>
        </div>
      )}
    </ExtrasRadioCardContentStyles>
  )
}
