import { typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'

import { GenericOverlay } from '../GenericOverlay/GenericOverlay'
import { PolicyUsageOverlayStyles } from './PolicyUsageOverlayStyles'
import { SDP, SDPBPH, SDPC, SDPCBPH, SDPCPH } from './Usages'
const { h3: H3 } = typographyRem

interface PolicyUsageOverlayProps {
  coverage: string
  usageCode: string
}

export const PolicyUsageOverlay = ({ coverage, usageCode }: PolicyUsageOverlayProps) => {
  const handleUsageSelector = (usageCode: string) => {
    switch (usageCode) {
      case 'SDPC':
        return <SDPC />
      case 'SDP+CPH':
        return <SDPCPH />
      case 'SDP+BPH':
        return <SDPBPH />
      case 'SDPC+BPH':
        return <SDPCBPH />
      default:
        return <SDP />
    }
  }
  return (
    <PolicyUsageOverlayStyles>
      <GenericOverlay
        overlay={
          <>
            <H3 className="overlay-subheading">{coverage}</H3>
            {handleUsageSelector(usageCode)}
          </>
        }
      />
    </PolicyUsageOverlayStyles>
  )
}
