import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

const {
  primaryColours,
  grid: { spacing, resolution },
  tints,
} = theme

export const ExtrasRadioCardContentStyles = styled.div`
  .free-legal-container {
    display: flex;
    gap: ${spacing.xxs}px;
    margin-top: ${spacing.xxs}px;
    flex-wrap: wrap;
    .tag {
      width: fit-content;
      color: ${primaryColours.aubergine.main};
    }
  }
  .breakdown-benefits {
    margin-top: ${spacing.s}px;

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: flex;
        gap: ${spacing.xs}px;
        margin-bottom: ${spacing.s}px;
        color: ${primaryColours.aubergine.main};
        @media (min-width: ${resolution.tablet.width}px) {
          margin-left: 5px;
        }
        .icon__tick {
          svg {
            position: relative;
            top: 2px;
          }
        }
      }
      &:nth-last-child(-n + 2) :nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: ${tints.darkAubergine};
    margin: ${spacing.s}px 0 0 0;
    width: 100%;
  }

  .price {
    color: ${primaryColours.aubergine.main};
    margin ${spacing.xxs}px 0 0 40px;
  }

  .above-bullets {
    margin-top: ${spacing.s}px;
    color: ${primaryColours.aubergine.main};
  }

  @media (max-width: ${resolution.mobile.width}px) {
    .divider {
      margin-top: ${spacing.xs}px;
    }
  }

  @media (min-width: ${resolution.tablet.width}px) {
    .breakdown-benefits {
      margin-top: ${spacing.s}px;
      .icon {
        margin-right: 12px;
      }
    }
  }
`
