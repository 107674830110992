import { IconCaretRight, theme, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'
const { paragraphBody: ParagraphBody } = typographyRem

const DocItemStyles = styled.div`
  .item {
    position: relative;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    .icon {
      display: flex;
    }

    .doc-title {
      color: ${theme.primaryColours.aubergine.main};
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: ${theme.tints.darkAubergine};
      bottom: 0;
    }
  }
`

export const DocumentItem = ({ title, href }) => (
  <DocItemStyles>
    <a className="item" href={href} target="_blank" rel="noreferrer">
      <ParagraphBody className="doc-title">{title}</ParagraphBody>
      <div>
        <IconCaretRight />
      </div>
    </a>
  </DocItemStyles>
)
