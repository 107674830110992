import { typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'
const { paragraphBody: ParagraphBody } = typographyRem

export const SDPCBPH = () => (
  <>
    <ParagraphBody className="overlay-text">
      This covers the policyholder and all named drivers for things like visiting family and friends, school runs and
      trips to the shops. The policyholder and all named drivers can also use the car to travel to and from their usual
      place of work.
    </ParagraphBody>
    <ParagraphBody className="overlay-text">
      In addition, the policyholder can use the car for work related purposes. This includes visiting clients,
      travelling to meetings, or driving to different sites, or locations in connection with work.
    </ParagraphBody>
    <ParagraphBody className="overlay-text">
      There is <span className="bold">no</span> cover for commercial travelling. This is where the car, or the journey
      is being used to make money.
    </ParagraphBody>
  </>
)
