import { Link, theme, typographyRem } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'
const { paragraphBody: ParagraphBody, h3: H3 } = typographyRem

export const CookieConsentOverlayStyles = styled.div`
  width: 100%;
  max-width: 328px;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};

  a {
    color: ${theme.primaryColours.aubergine.main};
    font-weight: bold;
  }

  .overlay-heading {
    margin-bottom: 24px;
  }

  .overlay-content {
    margin-bottom: 8px;
  }

  .overlay-button {
    width: 100%;
    margin-top: 48px;
  }
`

export const CookieConsentOverlay = ({ heading, content, button, href }) => (
  <CookieConsentOverlayStyles>
    <H3 className="overlay-heading">{heading}</H3>
    <ParagraphBody className="overlay-content">{content}</ParagraphBody>
    <Link href={href} text="Cookie notice"></Link>
    {button && button}
  </CookieConsentOverlayStyles>
)
